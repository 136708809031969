'use strict';
(function(){

class WopackageComponent {
  constructor(usSpinnerService, Auth, WOPackage, Util, $state) {
    this.WOPackage = WOPackage;
    this.usSpinnerService = usSpinnerService;
    this.isLoggedIn = Auth.isLoggedIn;
    this.Auth = Auth;
    this.getClass = Util.getFindingClass;

    this.Auth.isLoggedInAsync((loggedIn)=> {
      if (loggedIn) {
        this.init();
      } else {
        $state.go('login');
      }
    });
  }

  init() {
    this.loading = true;
    this.WOPackage.get({}).$promise
    .then(response=>{
    	// console.log(response);
      this.woPackages = response.data;
    	this.stopSpin('spinner-1');
      this.loading = false;
    })
    .catch(err=>{
    	this.stopSpin('spinner-1');
      this.loading = false;
    })
  }

  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .component('wopackage', {
    templateUrl: 'app/wopackage/wopackage.html',
    controller: WopackageComponent,
    controllerAs: 'wopc'
  });

})();
